<div class="container">
  <div class="content-box">
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>{{ "DOA.TITLE" | translate }}</h3>
      </div>
      <div class="ag-header-actions">
        <div class="btn-toolbar">
          <div class="btn-group"
          *ngIf="paginationData?.total_pages > 1">
            <paginator [data]="paginationData"></paginator>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <ng-container
          class="loading"
          *ngIf="doas; then showContent; else loading"
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #showContent>
  <div class="horizontal-scroller">
    <div class="table-sticky-headers">
      <table
        class="table table-condensed"
        AgMultiselection
        [(ngModel)]="selected"
        sortable
        [variableInUrl]="true"
      >
        <thead>
          <tr class="tr-align-center">
            <th class="sap micro" *ngIf="enableSelection && doas?.length > 0">
              <input type="checkbox" AgSelectall
              id="select-all-toggle"
              class="show-onhover no-margin"
              tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
              container="body" [adaptivePosition]="false">
            </th>
            <th class="sap" sortBy="created_at">
              {{ "GLOBAL.DATE" | translate }}
            </th>
            <th class="sap"></th>
            <th class="hidden-xs">
              {{ "GLOBAL.STATUS" | translate }}
              <column-filter
                type="custom"
                [options]="[
                  { name: 'DOA.STATUS.PENDING', value: 'PENDING' },
                  { name: 'DOA.STATUS.APPROVED', value: 'APPROVED' },
                  { name: 'DOA.STATUS.DISAPPROVED', value: 'DISAPPROVED' },
                  { name: 'DOA.STATUS.RETIRED', value: 'RETIRED' }
                ]"
                alignment="left"
                [filters]="filters"
                key="status"
                (onChange)="changeFilter($event)"
              ></column-filter>
            </th>
            <th>{{ "DOA.LINKED_TO" | translate }}</th>
            <th class="text-center sap">Tu voto</th>
            <th class="hidden-xs sap"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let doa of doas" class="tr-align-center">
            <td class="sap micro" *ngIf="enableSelection" clickStop>
              <input
                [AgCheckbox]="doa"
                type="checkbox"
                class="show-onhover no-margin"
              />
            </td>
            <td class="sap small">
              <ag-timestamp [date]="doa.createdAt"></ag-timestamp>
            </td>
            <td class="sap" [ngSwitch]="doa.status">
              <span
                *ngSwitchCase="'APPROVED'"
                class="material-symbols-rounded text-success"
                >thumb_up</span
              >
              <span
                *ngSwitchCase="'DISAPPROVED'"
                class="material-symbols-rounded text-danger"
                >thumb_down</span
              >
              <span
                *ngSwitchCase="'RETIRED'"
                class="material-symbols-rounded text-muted"
                >undo</span
              >
              <!-- <span *ngSwitchDefault class="material-symbols-rounded text-warning animated live">priority_high</span> -->
            </td>
            <td class="hidden-xs small" [ngSwitch]="doa.status">
              <span *ngSwitchCase="'APPROVED'" class="text-success">{{
                "DOA.STATUS." + doa.status | translate
              }}</span>
              <span *ngSwitchCase="'DISAPPROVED'" class="text-danger">{{
                "DOA.STATUS." + doa.status | translate
              }}</span>
              <span *ngSwitchCase="'RETIRED'" class="text-muted">{{
                "DOA.STATUS." + doa.status | translate
              }}</span>
              <span *ngSwitchDefault class="text-warning">{{
                "DOA.STATUS." + doa.status | translate
              }}</span>
            </td>
            <td [ngSwitch]="doa.entity.type">
              <a
                *ngSwitchCase="'financial-application'"
                [class.disabled]="processing"
                [routerLink]="[
                  '/company',
                  doa.company.id,
                  'fintech',
                  'applications',
                  doa.entity.id
                ]"
                >{{
                  doa.name || "FINTECH.APPLICATION_ID"
                    | translate : { id: doa.entity.id }
                }}</a
              >
              <code *ngSwitchDefault>{{
                doa.entity.type + " : " + doa.entity.id
              }}</code>
              &nbsp;
              <a [class.disabled]="processing"
              [routerLink]="['/company', doa.company.id, 'approvals', doa.id]"
                ><span class="material-symbols-rounded">search</span></a
              >
            </td>
            <td [ngSwitch]="myDecisions[doa.id]?.vote" class="text-center sap">
              <button
                *ngSwitchCase="'PENDING'"
                [disabled]="processing || doa.status !== 'PENDING'"
                [routerLink]="['/company', doa.company.id, 'approvals', doa.id]"
                type="button"
                class="btn btn-agree"
              >
                {{ "ORDER_VIEW.REVIEW" | translate }}
              </button>
              <span
                *ngSwitchCase="'APPROVED'"
                class="material-symbols-rounded text-success"
                >thumb_up</span
              >
              <span
                *ngSwitchCase="'DISAPPROVED'"
                class="material-symbols-rounded text-danger"
                >thumb_down</span
              >
              <span
                *ngSwitchCase="'ABSTENTION'"
                class="material-symbols-rounded text-muted"
                >highlight_off</span
              >
            </td>
            <td class="hidden-xs sap">
              <ag-timestamp
                *ngIf="myDecisions[doa.id]"
                [date]="myDecisions[doa.id].votedAt"
              ></ag-timestamp>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-container *ngIf="doas.length === 0">
      <!-- No data -->
      <div class="no-data">
        <i class="material-symbols-rounded">not_interested</i>
        {{ "GLOBAL.NO_DATA" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
  </div>
</ng-template>